import look from "request-promise";
import $ from "cheerio";

const ASL = async () => {
  const find = await fetch(
    "https://parser-external.geo.moveaws.com/search?input=135%20N%20Commonwealth%20Ave%2C%20Los%20Angeles%2C%20CA%2C%2090004&limit=1&client_id=rdc-home&area_types=city%2Ccounty%2Cpostal_code%2Caddress%2Cstreet%2Cneighborhood%2Cschool%2Cschool_district%2Cuniversity%2Cpark%2C&lat=-1&long=-1"
  );
  const findJSON = await find?.json();
  const targetID = findJSON?.hits[0]?.mpr_id;
  const half1 = targetID?.substring(0, 5);
  const half2 = targetID?.substring(5, targetID?.length);
  const targetIDparsed = `M${half1}-${half2}`;
  const html = await look(
    `http://pizzamethod.herokuapp.com/https://www.realtor.com/realestateandhomes-detail/135-N-Commonwealth-Ave_Los-Angeles_CA_90004_${targetIDparsed}`
  );

  // price:
  let target = $('h2', html);
  // let parseTarget1 = target[0]?.children[0]?.next?.children;
  // let parseTarget2 = parseTarget1?.slice(-1)[0]?.children[0]?.data;

  console.log(target, "<--- Realtor Target");
  // return parseTarget2;
};

export default ASL;

// const connect = require("request-promise");
// const c = require("cheerio");

// const main = async () => {
//   let prevDoc = await connect({
//     method: "GET",
//     url: "https://641d448c.us-east.apigw.appdomain.cloud/news/abc",
//     headers: {
//       newspaper: "40fdb15e-a59f-41b8-b9ea-562d8212f6b4",
//     },
//   });
//   prevDoc = await JSON.parse(prevDoc);
//   prevDoc = prevDoc.entries;
//   prevDoc.length > 0 ? (prevDoc = prevDoc[0]._rev) : (prevDoc = prevDoc.length);

//   return {
//     doc: {
//       _id: "0313c871c820b7270d39ad15385b8a23",
//       _rev: prevDoc,
//       updatedAt: new Date(),
//       prevDoc,
//       data: await getData(),
//     },
//   };
// };
// exports.main = main;

// const getData = async () => {
//   const html = await connect(`https://abcnews.go.com/us`);
//   let data = [];
//   let [time, title] = "";
//   let divs = c(".ContentRoll__Item", html);

//   // LOOP:
//   for (let i = 0; i < divs.length; i++) {
//     title =
//       divs[i].children[1].children[0].children[0].children[0].data || "none";
//     time = divs[i].children[0].children[0].children[0].data || "none";

//     data.push({
//       name: "ABC News",
//       title,
//       logo,
//       time,
//     });
//   }
//   return data.map((e) => e);
// };

// const logo =
//   "https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/American_Broadcasting_Company_Logo.svg/479px-American_Broadcasting_Company_Logo.svg.png";
// const placeHolder =
//   "https://ultralifecafe.com/wp-content/uploads/2019/12/placeholder.png";
