import React, { useState, useEffect } from "react";
import getZillow from "../../ASL/Zillow";
import getRealtor from "../../ASL/Realtor";
import getTrulia from "../../ASL/Trulia";
import { Spinner } from "../Atoms";
import "./smallbox.css";

const SmallBox = () => {
  const [zillow, setZillow] = useState("");
  const [trulia, setTrulia] = useState("");
  const [realtor, setRealtor] = useState("");

  // useEffect(() => {
  //   !zillow && setZillow(async () => await getZillow());
  //   // !realtor && setRealtor(await getRealtor());
  //   // !trulia && setTrulia(await getTrulia());
  // }, []);

  const getZillowFunction = async () => setZillow(await getZillow());
  const getRealtorFunction = async () => setRealtor(await getRealtor());

  useEffect(() => {
    getZillowFunction();
    getRealtorFunction();
  }, []);

  return (
    <div className="smallbox-container">
      <div className="smallbox">
        Zillow:
        <strong>{zillow ? zillow : <Spinner />}</strong>
      </div>
      <div className="smallbox">
        Realtor: <strong>{realtor ? realtor : <Spinner />}</strong>
      </div>
      <div className="smallbox">
        {/* Trulia: <strong>{trulia ? trulia : <Spinner />}</strong> */}
      </div>
    </div>
  );
};

export default SmallBox;
