import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import MapView from "./MapView";
import axios from "axios";
// import Token from "../../token.json";
import { URLtoAddress } from "../../methods/parseURL";
import { AddressContext, parseAddress } from "../../contexts/AddressContext";
import { IpContext } from "../../contexts/WifiContext";
import { LoadingLogo } from "../Atoms";

const MapWrapper = ({ zoom }) => {
  const location = useLocation();
  const [loc, setLoc] = useState({ latitude: 0, longitude: 0 });
  const { glob, saveGlobal } = useContext(AddressContext);
  const { IpData } = useContext(IpContext);

  // const { country, state, city, street } = useParams();
  const fetchData = async () => {
    try {
      let data = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${URLtoAddress(
          location.pathname
        )}.json?access_token=pk.eyJ1Ijoic3BvdGJveCIsImEiOiJja21vOXRyMTcwZ2dvMnFvY29ncG13YnRrIn0.zL6jJ2Pkbm5GLS-9iLzJAA`
      );
      console.log(
        "%c ⚛ fetching at MapWrapper.jsx",
        "background: green; color: white"
      );
      const details = data?.data?.features[0]?.context;
      const address = data?.data?.features[0]?.place_name;
      const number = data?.data?.features[0]?.address;
      const street = data?.data?.features[0]?.text;
      console.log(data?.data?.features[0], "full obj");

      const features = parseAddress(details);
      saveGlobal({ address, features, number, street });

      const latitude = data?.data?.features[0]?.center[0];
      const longitude = data?.data?.features[0]?.center[1];

      setLoc({ latitude, longitude });
    } catch (err) {
      console.log("NOTHING WAS FOUND!");
    }
  };

  const placeIp = () =>
    setLoc({ longitude: IpData.latitude, latitude: IpData.longitude });

  useEffect(() => {
    console.log(IpData, "<< ip in wrapper");
    console.log(
      "%c ⚛ useEffect on MapWrapper.jsx",
      "background: blue; color: white"
    );
    location.pathname.includes("/view") ? placeIp() : fetchData();
  }, []);

  return (
    <>
      {loc.latitude !== 0 && loc.longitude !== 0 ? (
        <MapView
          view={location.pathname.includes("/view") ? true : false}
          urlLocation={loc}
          zoom={zoom}
        />
      ) : (
        <LoadingLogo />
      )}
    </>
  );
};

export default MapWrapper;
