import React from "react";
import { MdLocationOn } from "react-icons/md";
import { HiLocationMarker } from "react-icons/hi";
import Logo from "../assets/hotspt-logo.png";
import HomeIcon from "./home.png";
import { Marker } from "react-map-gl";

export const X = (props) => (
  <div className="x" {...props}>
    ✕
  </div>
);

export const O = ({ color }) => <div className={`blob blob-${color}`} />;

export const V = () => <HiLocationMarker className="red-pin" />;

export const Spinner = () => (
  <img
    src="https://i.ibb.co/k0q8JGk/spinner.gif"
    style={{ width: 30, height: 30 }}
  />
);

export const LoadingLogo = () => (
  <div className="loading-transition">
    <HiLocationMarker className="loading-pin" />
    <span className="logo-text">MapHomes</span>
  </div>
);

export const HomeButton = ({ latitude, longitude, key, onClick, price }) => (
  <div key={key}>
    <Marker
      className="my-pin"
      offsetLeft={-30}
      offsetTop={-60}
      latitude={latitude}
      longitude={longitude}
      onClick={onClick}
    >
      <div className="home-button">
        <img src={HomeIcon} width={45} height={54} />
        <div className="home-text">{price}</div>
      </div>
    </Marker>
  </div>
);

{
  /* <img
      className="spot-pin pin"
      width="50"
      height="50"
      title=""
      alt=""
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEsAAABLCAYAAAA4TnrqAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABAZJREFUeNrsnO1tGkEQhveQ/4cUEAlXEFxAZKgAUkGgAocK4CoAKuCoIJcKOJQCoANflAYoIbPWxEIRYG5n3v1AHmnFD5u9vYd3Z2b3Zi8znu3Pw5c2fQypfabW5dY+8+8HantuW2rVp92vgwlkmWdATwxHYhbcklrpG1zmAdKU2uiCelztwNAWvqBlQFAzVlIbfA8v0AjYLDlYBMlOsx/UOp5dip2eY4K2R12gpQzqO33sAoAy7At3NIZR9MqiQa7YN8VgBSlsHCWsyEDBgGU3CgoCrCUENY8YlLURR+WwyqJBDDnqpWB9UlgVBBaB6nDUaycCy+Zi99Lk1XUazhMCZXisc+/KIlX16GNj0jTRdLxzVBXC/r+JHuAa0xPXwSgLoKqC2vrcr81BZKAccZ3V1dRnPSkNuGSHO740cPpbyXnSPX9Hw77BlcUR8FlhsBMCsBCsPTXcwEeXyNhEWUOFQY5dQbHS7Hc1MvIhehoOhAPM6WYL6V1yHwthNwM0LEl0qjU356ivie1T0EUPBoujoMhPAdKAXJKksg+GKKsjVFWpTYqno0RdUcIqDc4qn1PxWlgfBIPaAmEh+3aGJXnWh3xMVccI693eYWFg1ZGOvx0jrN++E8Ar7fHWpuEA2LdkvQpbSEvyma7CCuDcqkKS/+1RsKQ+awpQlbTPGgKLlha1dOGqWYPA+1oitfI9wXyWtDplzhU2UlBdBVU5uZUmsH4qhPmNBBh/d6OQMmzRsCqFGWRvcsfTqCmomdF7sOt0L02f7uyMvCb02MFe3D09qkWdGsWaL7qm05P4ps8N14qw7M2vuAqnOjE1HkEJrfOW0Z3DhRAPWXvgTF/F97Yayrc22M08H1Z6gaUUFUNaIamkaQxLYe87pK1DLKTXCYKqpQVtrrAWBrtdjLBc2oETLJ73y4RAHTQCk2Q/KyV1LTXO9zjDSkhdByOvjRArKxV1LbVOjYlgJaAuNVU1XkhfWPA+mzCHm96yiaQeTHsavg4q0rxqodmhCiyukqkig6V+KqylPLhYnH2pcfwEBot3JGJw9geEqrSVZbgUch8YVo46YN5KwVc0sErbqUNh8YHu/Jamn2qedSb30ny4cY19RdSuoqfh6+A9RscCDQoKi6Ojj2R17ysphpYc8RZ0gfZTvl6v4qM+awJMJ6BvCPEOi391hP/KffgpL9HwRHTsGb2DnZA3gkQDi4GN6GMldegE6iFExuu1tFvh+Jv1T/1Qy4MsxEUdX8nyAurmX2N3Bph928i11cYqL7FIahqeWHDvrwTVDw0qqLJYXbZYze7ft98AFXrbJ7iy/uVg/Qs52DgWUMFhMbBza7ux76QzelhHKcXxHthC46T+TZuNkJxWRGl/BRgAtAd0cl+QnEwAAAAASUVORK5CYII="
    /> */
}

{
  /* <div className="spot-pin pin-shadow"></div> */
}
{
  /* <MdLocationOn
      className="spot-pin"
      style={{ color: "#E4443D", fontSize: 60 }}
    /> */
}

// export const V = () => (
//   <svg
//     version="1.0"
//     xmlns="http://www.w3.org/2000/svg"
//     width="45pt"
//     height="45pt"
//     viewBox="0 0 45.000000 45.000000"
//     preserveAspectRatio="xMidYMid meet"
//     className="spot-pin"
//   >
//     <g
//       transform="translate(0.000000,45.000000) scale(0.030000,-0.030000)"
//       fill="#e4443d"
//       stroke="none"
//     >
//       <path
//         d="M543 1462 c-479 -166 -441 -770 87 -1352 l91 -100 110 120 c443 484
// 534 966 233 1231 -151 133 -329 167 -521 101z m264 -270 c248 -103 181 -462
// -87 -462 -265 0 -335 355 -92 462 79 34 99 34 179 0z"
//       />
//     </g>
//   </svg>
// );
