import React, { useState, useEffect, createContext } from "react";
import axios from "axios";

const IpContext = createContext();

const IpProvider = ({ children }) => {
  const [IpData, setIpData] = useState({
    longitude: 0,
    latitude: 0,
    country: "",
    IP: 0,

    // country: "United States",
    // IP: "100.8.201.126",
    // latitude: 40.72090148925781,
    // longitude: -74.04679870605469,
  });

  const fetchIpData = async () => {
    const data = await axios.get("https://ip.nf/me.json");
    console.log(data?.data, "<< first ip data");
    setIpData({
      ip: data.data.ip.ip,
      latitude: data.data.ip.latitude,
      longitude: data.data.ip.longitude,
      country: data.data.ip.country,
    });
  };

  useEffect(async () => {
    console.log(
      "%c ⚛ useEffect on WifiContext",
      "background: blue; color: white"
    );
  }, []);
  return (
    <IpContext.Provider value={{ IpData, fetchIpData, setIpData }}>
      {children}
    </IpContext.Provider>
  );
};
export { IpContext, IpProvider };
