import React, { useState, useEffect, createContext } from "react";
import GoogleStreet from "../assets/img/street.svg";

const AddressContext = createContext();

const AddressProvider = ({ children }) => {
  const [glob, setGlobal] = useState({
    features: {},
    address: "",
    number: "",
    street: "",
    image: GoogleStreet,
    price: "",
  });
  const saveGlobal = (e) =>
    setGlobal({
      features: e?.features,
      address: e?.address,
      number: e?.number,
      street: e?.street,
      image: e?.image ?? GoogleStreet,
      price: e?.price,
      nb: e?.nb,
      bed: e?.bed,
      bath: e?.bath,
      sqft: e?.sqft,
      built: e?.built,
    });

  useEffect(async () => {
    console.log(
      "%c ⚛ useEffect on AddressContext",
      "background: blue; color: white"
    );
  }, []);
  return (
    <AddressContext.Provider value={{ glob, saveGlobal }}>
      {children}
    </AddressContext.Provider>
  );
};
export { AddressContext, AddressProvider, parseAddress };

const parseAddress = (details) => {
  const features = {
    neighborhood: "",
    locality: "",
    place: "",
    district: "",
    region: "",
    postcode: "",
    country: "",
  };
  Object.keys(features).map((name) => {
    details?.map(
      (detail) => detail?.id?.includes(name) && (features[name] = detail?.text)
    );
  });
  return features;
};
