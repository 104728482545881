import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route } from "react-router-dom";
// import Token from "./token.json";
// import { LocationProvider, useLocation } from "./contexts/LocationContext";
import Router from "./Router";
import Fence from "./components/closed";
import useGeolocation from "react-hook-geolocation";
import { IpContext } from "./contexts/WifiContext";
import { LoadingLogo } from "./components/Atoms";
import Box from "./components/Unused/SmallBox";

// 3d ease and more
const App = () => {
  const [closed, setClosed] = useState(true);
  const geolocation = useGeolocation();
  const { IpData, fetchIpData } = useContext(IpContext);

  useEffect(() => {
    console.log("%c ⚛ useEffect on App.js", "background: blue; color: white");
    fetchIpData();
  }, []);

  const openGate = () => setClosed(false);
  return (
    <BrowserRouter>
      <Route exact path="/box" component={Box} />
      <Route
        path="/"
        children={
          <>
            {closed ? (
              <Fence openGate={openGate} />
            ) : (
              <>
                {IpData.longitude && IpData.latitude ? (
                  <Router geolocation={geolocation} />
                ) : (
                  <LoadingLogo />
                )}
              </>
            )}
          </>
        }
      />
    </BrowserRouter>
  );
};

export default App;
