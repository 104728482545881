import React, { useState, useRef } from "react";
// import { useAuth, googleLogin } from "../../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";

import { Form, Button, Card, Alert } from "react-bootstrap";

import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { MdDrafts as DraftsIcon } from "react-icons/md";
import {
  FiSend as SendIcon,
  FiMail as MailIcon,
  FiInbox as InboxIcon,
  FiList as ListItemIcon,
  FiLogOut as LogoutIcon,
} from "react-icons/fi";

const LoginMenu = ({ anchorEl, handleClose, toggleSignup }) => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, loginGoogle } = {};
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function googleHandle() {
    try {
      // await googleLogin();
      history.go(0);
    } catch {
      console.log("Failed to login using google");
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      // await login(emailRef.current.value, passwordRef.current.value);
      history.go(0);
    } catch {
      setError("Failed to log in");
    }

    setLoading(false);
  }

  // const { currentUser, logout } = useAuth();
  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      transitionDuration={{ enter: 0, exit: 0 }}
    >
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="w-100 text-center mt-2">
        <span onClick={googleHandle}>
          <img
            src="https://developers.google.com/identity/images/btn_google_signin_dark_normal_web.png"
            className="live"
          />
        </span>
      </div>
      <hr />
      <center style={{ paddingBottom: 10 }}>
        <span style={{ fontSize: 12 }}>
          Or enter username and password instead:
        </span>
      </center>
      <Form onSubmit={handleSubmit}>
        <Form.Group id="email">
          <Form.Control
            type="email"
            ref={emailRef}
            placeholder="Email"
            required
          />
        </Form.Group>
        <Form.Group id="password">
          <Form.Control
            type="password"
            ref={passwordRef}
            placeholder="Password"
            required
          />
        </Form.Group>
        <Button disabled={loading} className="w-100" type="submit">
          Log In
        </Button>
      </Form>
      <div className="w-100 text-center mt-3">
        <Link to="/forgot-password">Forgot Password?</Link>
      </div>
      <div className="w-100 text-center mt-2">
        Need an account? <a onClick={toggleSignup}>Sign Up</a>
      </div>
    </StyledMenu>
  );
};

export default LoginMenu;

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    minWidth: 300,
    padding: 20,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#dbdbdb",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#000",
      },
    },
  },
}))(MenuItem);
