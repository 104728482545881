import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
// import Token from "../../token.json";
import { addressToURL } from "../../methods/parseURL";
import { IpContext } from "../../contexts/WifiContext";
import { AddressContext, parseAddress } from "../../contexts/AddressContext";

const MapRedirect = () => {
  let history = useHistory();
  const { IpData, setIpData } = useContext(IpContext);
  const { glob, saveGlobal } = useContext(AddressContext);

  const fetchURL = async () => {
    // let data = await axios.get(
    //   `https://api.mapbox.com/geocoding/v5/mapbox.places/${IpData.longitude},${IpData.latitude}.json?types=address&access_token=pk.eyJ1Ijoic3BvdGJveCIsImEiOiJja21vOXRyMTcwZ2dvMnFvY29ncG13YnRrIn0.zL6jJ2Pkbm5GLS-9iLzJAA`
    // );
    console.log(
      "%c ⚛ fetching at MapRedirect.jsx",
      "background: green; color: white"
    );

    // console.log(IpData, "<< ip data");
    // if (data?.data?.features?.length === 0) {

    setIpData({
      longitude: -73.9813285,
      latitude: 40.7646308,
      country: "United States",
      IP: 0,
    });
    let data = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/-73.9813285,40.7646308.json?types=address&access_token=pk.eyJ1Ijoic3BvdGJveCIsImEiOiJja21vOXRyMTcwZ2dvMnFvY29ncG13YnRrIn0.zL6jJ2Pkbm5GLS-9iLzJAA`
    );
    // }
    const details = data?.data?.features[0]?.context;
    const address = data?.data?.features[0]?.place_name;
    const number = data?.data?.features[0]?.address;
    const street = data?.data?.features[0]?.text;
    const postcode = data?.data?.features[0]?.text;
    console.log(data?.data, "<< full obj");

    const features = parseAddress(details);
    saveGlobal({ address, features, number, street });

    const placeToGo =
      features?.neighborhood || features?.locality || features?.place
        ? addressToURL(
            `${features?.country && features?.country + "/"}${
              features?.region && features?.region + "/"
            }${
              features?.locality
                ? features?.locality + "/"
                : features?.place + "/"
            }${features?.neighborhood && features?.neighborhood + "/"}view`
          )
        : `${addressToURL(address)}/view`;

    history.push(placeToGo);
  };
  useEffect(() => {
    console.log(
      "%c ⚛ useEffect on MapRedirect.jsx",
      "background: purple; color: white"
    );
    fetchURL();
  }, []);
  return null;
};

export default MapRedirect;

// console.log(details, "<---- details map");
// console.log(features.neighborhood, "<--- hood");
// console.log(features.locality, "<--- locality");
// console.log(features.place, "<--- place");
// console.log(features.district, "<--- district");
// console.log(features.region, "<--- region");
// console.log(features.country, "<--- country");
