import React, { useState, useContext, useEffect } from "react";
import { AddressContext } from "../../../contexts/AddressContext";
import { DatabarContext } from "../../../contexts/DatabarContext";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import Skeleton from "@material-ui/lab/Skeleton";
import ContentLoader from "react-content-loader";

const AddressView = () => {
  const { glob } = useContext(AddressContext);
  const {
    loading,
    setLoading,
    loadingAddress,
    setLoadingAddress,
    imgLoadingSpeed,
  } = useContext(DatabarContext);

  const { region, place, postcode, neighborhood, locality, country } =
    glob?.features;
  const { number, street } = glob;

  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log(
      "%c ⚛️ useEffect on AddressView  ",
      "background: blue; color: white"
    );
    setTimeout(() => setLoading(false), 3500);
    setTimeout(() => setLoadingAddress(false), 2800);
  }, []);
  return (
    <div className="column" className="address-view">
      {/* {JSON.stringify(glob)} */}
      <div className="div-a3c0al4f">
        {loading ? (
          <ContentLoader
            speed={imgLoadingSpeed}
            // backgroundColor="rgb(242, 240, 236)"
            // foregroundColor="#fcfcfc"
            backgroundColor="#f5f5f5"
            foregroundColor="#fff"
            width={"100%"}
            height={157}
          >
            <rect x="0" y="0" rx="0" ry="0" width={"100%"} height={157} />
          </ContentLoader>
        ) : (
          <div
            className="img-box"
            style={{
              backgroundImage: `url(${glob?.image})`,
            }}
          >
            {/* <img style={{ maxWidth: "100%", height: "auto" }} src={glob?.image} /> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressView;
