import React, { useState } from "react";
import { AiFillCloud } from "react-icons/ai";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Logo1 from "./maphomes-logo-bold.png";

const Closed = ({ openGate }) => {
  const [pass, setPass] = useState("");
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const validate = () =>
    pass === "Nzr101"
      ? openGate()
      : alert("Messages system is not connected. Please e-mail us directly.");

  return (
    <div className="smallbox-container">
      <div className="smallbox" style={{ maxWidth: 500, textAlign: "center" }}>
        {/* <AiFillCloud
          style={{
            fontSize: 60,
            color: "#dbdbdb",
            margin: "0 auto",
          }}
        /> */}
        <img
          src={Logo1}
          style={{ width: "50%", margin: "0 auto", marginTop: 33 }}
        />
        <br />
        <div>
          The beta version is available for testing upon request.
          <br />
          {/* Please e-mail us for more information. */}
        </div>
        <div>
          Drop us an email at{" "}
          <a href="mailto:hello@map.homes">hello@map.homes</a>.
        </div>
        <br />
      </div>
      <br />
      <button type="button" onClick={handleOpen} style={{ opacity: 0 }}>
        react-transition-group
      </button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            Messages system is not connected. Please e-mail us directly.
            <br />
            <br />
            <input
              type="text"
              placeholder="Your message"
              onChange={(e) => setPass(e.target.value)}
            />
            <button onClick={validate}>Send</button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Closed;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export function TransitionsModal() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
}
