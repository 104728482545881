import React, { useState, useEffect, createContext } from "react";

const DatabarContext = createContext();

const DatabarProvider = ({ children }) => {
  const [toggle, setToggle] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingAddress, setLoadingAddress] = useState(true);
  const [imgLoadingSpeed, setImgLoadingSpeed] = useState(1);

  const dataBarWidth = 450;
  const toggleFunction = () => setToggle(!toggle);
  const openDataBar = () => setToggle(true);

  useEffect(async () => {
    console.log(
      "%c ⚛ useEffect on DatabarContext",
      "background: blue; color: white"
    );
  }, []);
  return (
    <DatabarContext.Provider
      value={{
        toggle,
        toggleFunction,
        openDataBar,
        dataBarWidth,
        loading,
        setLoading,
        loadingAddress,
        setLoadingAddress,
        imgLoadingSpeed,
        setImgLoadingSpeed,
      }}
    >
      {children}
    </DatabarContext.Provider>
  );
};
export { DatabarContext, DatabarProvider };
