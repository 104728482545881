import look from "request-promise";
import $ from "cheerio";

const ASL = async () => {
  const html = await look(
    `http://pizzamethod.herokuapp.com/https://www.zillow.com/homes/135-N-Commonwealth-Ave-Los-Angeles,-CA-90004_rb`
  );

  // price:
  let target = $('span:contains("Zestimate")', html);
  let parseTarget1 = target[0]?.children[0]?.next?.children;
  let parseTarget2 = parseTarget1?.slice(-1)[0]?.children[0]?.data;

  console.log(parseTarget2, "<--- Zillow target");
  return parseTarget2;
};

export default ASL;

// const connect = require("request-promise");
// const c = require("cheerio");

// const main = async () => {
//   let prevDoc = await connect({
//     method: "GET",
//     url: "https://641d448c.us-east.apigw.appdomain.cloud/news/abc",
//     headers: {
//       newspaper: "40fdb15e-a59f-41b8-b9ea-562d8212f6b4",
//     },
//   });
//   prevDoc = await JSON.parse(prevDoc);
//   prevDoc = prevDoc.entries;
//   prevDoc.length > 0 ? (prevDoc = prevDoc[0]._rev) : (prevDoc = prevDoc.length);

//   return {
//     doc: {
//       _id: "0313c871c820b7270d39ad15385b8a23",
//       _rev: prevDoc,
//       updatedAt: new Date(),
//       prevDoc,
//       data: await getData(),
//     },
//   };
// };
// exports.main = main;

// const getData = async () => {
//   const html = await connect(`https://abcnews.go.com/us`);
//   let data = [];
//   let [time, title] = "";
//   let divs = c(".ContentRoll__Item", html);

//   // LOOP:
//   for (let i = 0; i < divs.length; i++) {
//     title =
//       divs[i].children[1].children[0].children[0].children[0].data || "none";
//     time = divs[i].children[0].children[0].children[0].data || "none";

//     data.push({
//       name: "ABC News",
//       title,
//       logo,
//       time,
//     });
//   }
//   return data.map((e) => e);
// };

// const logo =
//   "https://upload.wikimedia.org/wikipedia/commons/thumb/5/54/American_Broadcasting_Company_Logo.svg/479px-American_Broadcasting_Company_Logo.svg.png";
// const placeHolder =
//   "https://ultralifecafe.com/wp-content/uploads/2019/12/placeholder.png";
