import React, { useState, useEffect, useRef, useContext } from "react";
// import LocationData from "./LocationData";
import { DatabarContext } from "../../../contexts/DatabarContext";
import { AddressContext } from "../../../contexts/AddressContext";
import ImageView from "./ImageView";
import AddressView from "./AddressView";
import TopTabs from "./TopTabs";
import Overview from "./Overview";
import Results from "./Results";
// import getZillow from "../../../ASL/Zillow";
import ResultBox from "./ResultBox";

const DataBar = ({ geolocation, transition }) => {
  const { glob } = useContext(AddressContext);
  const { number, street, address, nb } = glob;
  const { toggle, dataBarWidth } = useContext(DatabarContext);
  const [dataBarBox, setDataBarBox] = useState("hide-scroll");
  const [tab, setTab] = useState(1);

  useEffect(() => {
    document.title = `${number || ""} ${street || ""} - MapHomes`;
  }, [glob]);

  const [zillow, setZillow] = useState("");
  // const getZillowFunction = async () => setZillow(await getZillow());
  // useEffect(() => getZillowFunction(), []);

  return (
    // <div className={`databar ${toggle ? "show-scroll" : "hide-scroll"}`}>
    <div
      className={`databar ${dataBarBox}`}
      onMouseLeave={() => setDataBarBox("hide-scroll")}
      onMouseEnter={() => setDataBarBox("show-scroll")}
    >
      <div
        style={{
          width: toggle ? dataBarWidth : 0,
          transition,
        }}
      >
        <ImageView />
        <div className="sticky-top">
          <AddressView />
          <TopTabs tab={tab} setTab={setTab} />
        </div>

        {tab === 1 && (
          <div
            style={{
              padding: 18,
              height: "calc(100vh - 400px)",
              background: "#fff",
            }}
          >
            {/* {number} {street} <br /> */}
            {address} <br />
            {nb}sgfsgcs
            {/* <Overview zillow={zillow} /> */}
          </div>
        )}
        {tab === 2 && <Results type="nearby" />}
        {tab === 3 && <Results type="similar" />}
        {/* <LocationData geolocation={geolocation} /> */}
        <br />
      </div>
    </div>
  );
};

export default DataBar;

const DataShow = ({ children }) => {
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setWidth(ref.current.clientWidth);
  });

  return (
    <div
      style={{
        opacity: width >= 420 ? 1 : 0,
        transition: "0.2s",
      }}
      ref={ref}
    >
      {width > 425 && <>{children}</>}
    </div>
  );
};
