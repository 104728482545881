import React, { useState, createContext } from "react";

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [color, setColor] = useState();
  const colorFunction = (color) => setColor(color);
  return (
    <ThemeContext.Provider value={{ color, colorFunction }}>
      {children}
    </ThemeContext.Provider>
  );
};
export { ThemeContext, ThemeProvider };
