import React, { useState, useEffect, useRef, useContext } from "react";

import ContentLoader from "react-content-loader";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { AddressContext } from "../../../contexts/AddressContext";
import { DatabarContext } from "../../../contexts/DatabarContext";
import HomeIcon from "../../../assets/home-icon.jpg";

const AddressView = () => {
  const { glob } = useContext(AddressContext);
  const {
    loading,
    setLoading,
    loadingAddress,
    setLoadingAddress,
    imgLoadingSpeed,
  } = useContext(DatabarContext);

  // const { region, place, postcode, neighborhood, locality, country } =
  //   glob?.features;
  const [star, setStar] = useState(false);
  const { region, place, postcode, neighborhood, locality, country } =
    glob?.features;
  const { number, street, price } = glob;

  return (
    <div className="address-view-text" style={{ padding: 18, paddingLeft: 12 }}>
      {/* {glob?.address} */}
      <div className="row">
        {loadingAddress === "4" ? (
          <ContentLoader
            speed={0.9}
            backgroundColor="#e8e8e8"
            foregroundColor="#fafafa"
            width={"65%"}
            height={10}
            className="address-skeleton"
          >
            <rect x="0" y="0" rx="0" ry="0" width={"100%"} height={157} />
          </ContentLoader>
        ) : (
          <h5 className=" bolder">
            {/* <div
              className="thumbnail-img"
              style={{ backgroundImage: `url(${HomeIcon})` }}
            /> */}
            <div>
              {number} {street}{" "}
            </div>
            {price && (
              <h3
                style={{ fontWeight: "900", marginTop: 10, marginBottom: -20 }}
              >
                ${price}
              </h3>
            )}
          </h5>
        )}
        <div
          style={{ height: "100%", marginTop: 3 }}
          onMouseOver={() => setStar(true)}
          onMouseLeave={() => setStar(false)}
        >
          {star ? (
            <AiFillStar
              className="toolbar-icon"
              style={{ color: "#1095F6" }}
              onMouseOver={() => setStar(true)}
            />
          ) : (
            <AiOutlineStar
              className="toolbar-icon"
              style={{ color: "#1095F6" }}
              onMouseOut={() => setStar(false)}
            />
          )}
        </div>
      </div>
      {loadingAddress === "4" ? (
        <ContentLoader
          speed={0.9}
          backgroundColor="#e8e8e8"
          foregroundColor="#fafafa"
          width={"55%"}
          height={10}
        >
          <rect x="0" y="0" rx="0" ry="0" width={"100%"} height={157} />
        </ContentLoader>
      ) : (
        <div>
          {(locality && locality + ", ") ||
            (place && place + ", ") ||
            (neighborhood && neighborhood + ", ")}
          {region} {postcode || ""}
        </div>
      )}
      {/* {country?.replace("/", "")} */}
    </div>
  );
};

export default AddressView;
