import React from "react";
import { Route } from "react-router-dom";
import MapWrapper from "./components/MapView/MapWrapper";
import MapRedirect from "./components/MapView/MapRedirect";
import TopRight from "./components/Interface/TopRight";
import { DatabarProvider } from "./contexts/DatabarContext";
import { AddressProvider } from "./contexts/AddressContext";
import { ThemeProvider } from "./contexts/themeContext";
// import { AuthProvider } from "./contexts/AuthContext";

const Router = ({ geolocation }) => (
  // <AuthProvider>
  <ThemeProvider>
    <DatabarProvider>
      <AddressProvider>
        <Route exact path="/" children={<MapRedirect />} />
        <div>
          <div className="right-bar">
            <TopRight />
          </div>
          <div>
            <Route
              path="/us/:address"
              children={<MapWrapper zoom={17} geolocation={geolocation} />}
            />
          </div>
        </div>
      </AddressProvider>
    </DatabarProvider>
  </ThemeProvider>
  // </AuthProvider>
);

export default Router;
