import React from "react";
import ContentLoader from "react-content-loader";
import HomeIcon from "../../../assets/home-icon.jpg";
import GoogleHome from "../../../assets/img/google-home.png";
import ShopIcon from "../../../assets/shop-icon.png";
// import StoreIcon from

const ResultBox = ({ loadingAddress, result }) => (
  <div className="result-box">
    {loadingAddress ? (
      <div className="flex-row result-box-skeleton">
        <div style={{ width: "15%" }}>
          <ContentLoader
            speed={0.5}
            backgroundColor="#eeeeee"
            foregroundColor="#f9f9f9"
            width={"100%"}
            height={50}
            style={{ zIndex: 999 }}
          >
            <circle cx="25" cy="25" r="25" />
          </ContentLoader>
        </div>
        <div style={{ width: "85%" }}>
          <ContentLoader
            speed={0.5}
            backgroundColor="#eeeeee"
            foregroundColor="#f9f9f9"
            width={"100%"}
            height={40}
          >
            <rect x="0" y="12" rx="3" ry="3" width="180" height="8" />
            <rect x="0" y="32" rx="3" ry="3" width="200" height="8" />
          </ContentLoader>
        </div>
      </div>
    ) : (
      <div className="flex-row result">
        <div
          className="result-img"
          style={{
            backgroundImage: result?.img
              ? `url(${result?.image})`
              : `url(${
                  result?.asset === "home"
                    ? HomeIcon
                    : result?.asset === "shop"
                    ? ShopIcon
                    : "https://cdn-icons-png.flaticon.com/512/3616/3616413.png"
                })`,
            // : `url(${GoogleHome})`,
          }}
        />
        <div className="flex-column" style={{ marginTop: 5, marginLeft: 15 }}>
          <div className="result-title">{result?.title}</div>
          <div className="result-subtitle">{result?.subtitle}</div>
        </div>
      </div>
    )}
  </div>
);

export default ResultBox;
