import React from "react";
// import { useAuth } from "../../../contexts/AuthContext";

import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { BiHomeAlt } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { FiGlobe, FiLogOut, FiSettings } from "react-icons/fi";

const LogoutMenu = ({ anchorEl, handleClose, handleLogout, error }) => {
  const { currentUser, logout } = {};
  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      transitionDuration={{ enter: 0, exit: 0 }}
    >
      <ListItemText
        primary={currentUser?.displayName}
        style={{ padding: 12, textAlign: "center" }}
      />
      <StyledMenuItem>
        <CgProfile className="drop-icon" />
        <ListItemText primary="Profile" />
      </StyledMenuItem>
      {currentUser.uid === "QNuOdAq88wZ8jc4Xjw08YgtumQh2" && (
        <StyledMenuItem>
          <FiGlobe className="drop-icon" />
          <ListItemText primary="Dashboard" />
        </StyledMenuItem>
      )}
      <StyledMenuItem>
        <BiHomeAlt className="drop-icon" />
        <ListItemText primary="Saved Properties" />
      </StyledMenuItem>
      <StyledMenuItem>
        <FiSettings className="drop-icon" />
        <ListItemText primary="Settings" />
      </StyledMenuItem>
      <StyledMenuItem
        onClick={handleLogout}
        style={{ borderTop: "1px solid silver" }}
      >
        <FiLogOut className="drop-icon" />
        <ListItemText primary="Logout" />
        {error && error}
      </StyledMenuItem>
    </StyledMenu>
  );
};

export default LogoutMenu;

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    minWidth: 200,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    minWidth: 300,
    paddingLeft: 20,
    "&:focus": {
      backgroundColor: "#dbdbdb",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#000",
      },
    },
  },
}))(MenuItem);
