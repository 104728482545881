export const addressToURL = (str) =>
  str
    .replace("United States", "us")
    .replace("Israel", "il")
    .toLowerCase()
    .split(",")
    .reverse()
    .map((item) => {
      const postalRegex = /[0-9]{5}/i;
      return item?.replace(postalRegex, "")?.trim()?.replaceAll(" ", "-");
    })
    .join("/");

export const URLtoAddress = (str) =>
  str
    .split("")
    .map((e) => (e === "/" ? e.replace("/", " ") : e))
    .map((e) => (e === "-" ? e.replace("-", " ") : e))
    .join("");
