import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Button, Card, Alert } from "react-bootstrap";
// import { useAuth } from "../../../contexts/AuthContext";

import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const SignupMenu = ({ anchorEl, handleClose, toggleSignup }) => {
  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = {};
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    try {
      setError("");
      setLoading(true);
      // await signup(
      //   nameRef.current.value,
      //   emailRef.current.value,
      //   passwordRef.current.value
      // );
      history.go(0);
    } catch {
      setError("Failed to create an account");
    }

    setLoading(false);
  }
  return (
    <StyledMenu
      id="customized-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      transitionDuration={{ enter: 0, exit: 0 }}
    >
      <center style={{ paddingBottom: 30 }}>
        <div className="w-100 text-center mt-2">Create a new account</div>
      </center>
      <Form onSubmit={handleSubmit}>
        <Form.Group id="displayName">
          <Form.Control type="text" ref={nameRef} placeholder="Name" required />
        </Form.Group>
        <Form.Group id="email">
          <Form.Control
            type="email"
            ref={emailRef}
            placeholder="Email"
            required
          />
        </Form.Group>
        <Form.Group id="password">
          <Form.Control
            type="password"
            ref={passwordRef}
            placeholder="Password"
            required
          />
        </Form.Group>
        <Form.Group id="password-confirm">
          <Form.Control
            type="password"
            ref={passwordConfirmRef}
            placeholder="Confirm password"
            required
          />
        </Form.Group>
        <Button disabled={loading} className="w-100" type="submit">
          Sign Up
        </Button>
      </Form>
      <div className="w-100 text-center mt-2">
        Already have an account? <Link onClick={toggleSignup}>Log In</Link>.
        {error && <Alert variant="danger">{error}</Alert>}
      </div>
    </StyledMenu>
  );
};

export default SignupMenu;

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    minWidth: 300,
    padding: 20,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#dbdbdb",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#000",
      },
    },
  },
}))(MenuItem);
