import React from "react";
import {
  HiOutlineEye,
  HiOutlineLocationMarker,
  HiOutlineMap,
} from "react-icons/hi";

const TopTabs = ({ tab, setTab }) => (
  <div className="flex-row address-tabs">
    <div
      onClick={() => setTab(1)}
      className={`address-tab ${tab === 1 && "address-tab-selected"}`}
    >
      <HiOutlineEye className="address-tab-icon" /> OVERVIEW
    </div>
    <div
      onClick={() => setTab(2)}
      className={`address-tab ${tab === 2 && "address-tab-selected"}`}
    >
      <HiOutlineLocationMarker className="address-tab-icon" /> AROUND
    </div>
    <div
      onClick={() => setTab(3)}
      className={`address-tab ${tab === 3 && "address-tab-selected"}`}
    >
      <HiOutlineMap className="address-tab-icon" /> SIMILAR
    </div>
  </div>
);

export default TopTabs;
