import React, { useEffect, useState } from "react";
// import { useAuth } from "../../../contexts/AuthContext";
import { useHistory } from "react-router-dom";

import LoginMenu from "./LoginMenu";
import LogoutMenu from "./LogoutMenu";
import SignupMenu from "./SignupMenu";

const TopRight = () => {
  const [error, setError] = useState("");
  const [signup, setSignup] = useState(false);
  const { currentUser, logout } = {};
  const history = useHistory();

  const handleLogout = async () => {
    setError("");
    try {
      // await logout();
      history.go(0);
    } catch {
      setError("Failed to log out");
    }
  };

  useEffect(() => console.log(currentUser, "USER from RightBar.jsx!"), []);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const toggleSignup = () => setSignup(!signup);

  return (
    <div style={{ borderRadius: "50%" }}>
      <img
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        className="avatar live"
        src={currentUser?.photoURL ?? "https://i.ibb.co/qrbTQMf/profile.jpg"}
      />
      {currentUser ? (
        <LogoutMenu
          handleClick={handleClick}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleLogout={handleLogout}
          error={error}
        />
      ) : !signup ? (
        <LoginMenu
          handleClick={handleClick}
          anchorEl={anchorEl}
          handleClose={handleClose}
          toggleSignup={toggleSignup}
        />
      ) : (
        <SignupMenu
          handleClick={handleClick}
          anchorEl={anchorEl}
          handleClose={handleClose}
          toggleSignup={toggleSignup}
        />
      )}
    </div>
  );
};

export default TopRight;
