import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import Map, { Marker, GeolocateControl, FlyToInterpolator } from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import axios from "axios";
// import Token from "../../token.json";
import { O, V, HomeButton } from "../Atoms";
import { addressToURL } from "../../methods/parseURL";
import { IpContext } from "../../contexts/WifiContext";
import { DatabarContext } from "../../contexts/DatabarContext";
import { AddressContext, parseAddress } from "../../contexts/AddressContext";
// import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "./MapStyle.css";
// import ToggleDataBar from "../Interface/ToggleDataBar";
import DataBar from "../Interface/DataBar";
import Toolbar from "../Interface/DataBar/Toolbar";
import { FiSearch } from "react-icons/fi";
import { HiLocationMarker } from "react-icons/hi";
import { VscMenu } from "react-icons/vsc";

const MapViewURL = ({
  geolocation,
  urlLocation,
  view,
  zoom,
  upperGeocoderContainerRef,
}) => {
  const [theSpot, setSpot] = useState({ latitude: 0, longitude: 0 });
  const [showSpot, setShowSpot] = useState(true);
  const [viewport, setViewport] = useState({
    longitude: urlLocation.latitude,
    latitude: urlLocation.longitude,
    zoom: view ? 13 : zoom,
    pitch: 50,
  });
  const transition = "0.1s";
  const { IpData } = useContext(IpContext);
  const {
    // toggle,
    dataBarWidth = 0,
    loading,
    setLoading,
    loadingAddress,
    setLoadingAddress,
    setImgLoadingSpeed,
  } = useContext(DatabarContext);
  const { saveGlobal } = useContext(AddressContext);
  const history = useHistory();
  const mapRef = useRef();
  const geocoderContainerRef = useRef();

  const handleViewportChange = useCallback(
    (newViewport) => setViewport(newViewport),
    []
  );

  const handleGeocoderViewportChange = useCallback(
    (newViewport) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 };
      Spot(newViewport.latitude, newViewport.longitude);
      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides,
      });
    },
    [handleViewportChange]
  );

  const Spot = useCallback(async (latitude, longitude, isHome) => {
    setLoading(true);
    setLoadingAddress(true);
    !isHome && setSpot({ latitude, longitude });

    try {
      // repeating code for fetching function
      let data = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?types=address&access_token=pk.eyJ1Ijoic3BvdGJveCIsImEiOiJja21vOXRyMTcwZ2dvMnFvY29ncG13YnRrIn0.zL6jJ2Pkbm5GLS-9iLzJAA`
      );
      console.log(
        "%c ⚛ fetching at MapView.jsx",
        "background: green; color: white"
      );
      const details = data?.data?.features[0]?.context;
      const address = data?.data?.features[0]?.place_name;
      const number = data?.data?.features[0]?.address;
      const street = data?.data?.features[0]?.text;
      console.log(data?.data?.features[0], "full obj");

      const features = parseAddress(details);
      saveGlobal({ address, features, number, street });

      history.push(`/${addressToURL(address)}`);
      address &&
        setViewport({
          latitude,
          longitude,
          zoom: 14,
          pitch: 50,
          transitionInterpolator: new FlyToInterpolator({ speed: 0 }),
          transitionDuration: 200,
          transitionInterruption: 0,
        });

      // openDataBar();

      setImgLoadingSpeed(0.7);
      setTimeout(() => setLoading(false), 300);
      setTimeout(() => setLoadingAddress(false), 250);
    } catch (err) {
      console.log("NO ADDRESS!");
      setSpot({ latitude: 0, longitude: 0 });
    }
  }, []);

  useEffect(
    () =>
      console.log(
        "%c ⚛ useEffect on MapView.jsx",
        "background: blue; color: white"
      ),
    []
  );

  const [searchField, setSearchField] = useState("asdf");
  const [isLoadingText, setIsLoadingText] = useState(false);

  // useEffect(() => setSearchField(searchField), [searchField]);

  const homesData = [
    {
      latitude: 40.7154302,
      longitude: -73.9971708,
      address: "50 Bayard St APT 2Y, New York, NY 10013",
      provider: "zillow",
      bed: 1,
      bath: 1,
      sqft: 702,
      type: "Condo",
      hoa: 581,
      built: 1962,
      price: "795K",
      type: "condo",
      mainPhoto:
        "https://photos.zillowstatic.com/fp/3eb83a1951cb820fda4ff4ba200578f1-cc_ft_1536.webp",
    },
    {
      address: "25 Murray St APT 8F, New York, NY 10007",
      latitude: 40.7138174,
      longitude: -74.0083295,
      price: "1.88M",
      type: "Condo",
      bed: 2,
      bath: 2,
      sqft: 1385,
      built: 1930,
      hoa: 1509,
      mainPhoto:
        "https://photos.zillowstatic.com/fp/4f8fed87c312b86021c92d193426e698-cc_ft_1536.webp",
    },
    {
      address: "491 Broadway #PENTHOUSE, New York, NY 10012",
      price: "4.5M",
      latitude: 40.7220942,
      longitude: -74.0000326,
      bed: 4,
      bath: 3,
      built: 1897,
      hoa: 5000,
      type: "Stock Cooperative",
      nb: "SoHo",
      mainPhoto:
        "https://photos.zillowstatic.com/fp/68d10bffad0a8a10fe7b6005f72a1aa2-cc_ft_768.webp",
    },
    {
      address: "2109 Broadway APT 4160, New York, NY 10023",
      price: "1.25M",
      latitude: 40.7801432,
      longitude: -73.9822098,
      bed: 2,
      bath: 2,
      sqft: 921,
      built: 1902,
      hoa: 1633,
      type: "Condo",
      nb: "Upper West Side",
      mainPhoto:
        "https://photos.zillowstatic.com/fp/999bbdb5805052e1713dcbf402f57f3f-cc_ft_1536.webp",
    },
    {
      address: "1 Central Park S #706, New York, NY 10019",
      price: "4.5M",
      latitude: 40.7644782,
      longitude: -73.97402,
      bed: 2,
      bath: 2,
      sqft: 1721,
      built: 1907,
      hoa: 2872,
      type: "Condo",
      nb: "Midtown",
      mainPhoto:
        "https://photos.zillowstatic.com/fp/97aec846e641daceb4b6baab0e9b99d5-cc_ft_1536.webp",
    },
    {
      address: "140 E 56th St APT 7H, New York, NY 10022",
      price: "875K",
      latitude: 40.7599182,
      longitude: -73.9694223,
      bed: 1,
      bath: 1,
      built: 1956,
      hoa: 656,
      type: "Condo",
      nb: "Sutton Place",
      mainPhoto:
        "https://photos.zillowstatic.com/fp/4134a85fba8f9262c8878d51297030a7-cc_ft_768.webp",
    },
    {
      address: "350 E 62nd St APT 4L, New York, NY 10065",
      price: "1.1M",
      latitude: 40.7614888,
      longitude: -73.9611342,
      bed: 2,
      bath: 1,
      sqff: 1000,
      hoa: 1050,
      built: 1977,
      type: "Condo",
      mainPhoto:
        "https://photos.zillowstatic.com/fp/2ba11a753a36c5ad1bae8931247acb15-cc_ft_1536.webp",
      nb: "Upper East Side",
    },
    {
      address: "247 W 46th St #3505, New York, NY 10036",
      price: "2.15M",
      latitude: 40.759848,
      longitude: -73.9874391,
      bed: 2,
      bath: 3,
      sqft: 1311,
      built: 2007,
      hoa: 1373,
      type: "Condo",
    },
    {
      address: "301 Park Ave UNIT 3301, New York, NY 10022",
      price: "2.92M",
      latitude: 40.7565332,
      longitude: -73.9735637,
      bed: 1,
      bath: 2,
      sqft: 814,
      built: 1931,
      hoa: 1519,
      type: "Condo",
      mainPhoto:
        "https://photos.zillowstatic.com/fp/77788a60fc47f9ba84534e42482a3c20-cc_ft_1536.webp",
    },
    {
      address: "330 E 38th St #50NO, New York, NY 10016",
      price: "3.15M",
      latitude: 40.7464012,
      longitude: -73.972838,
      bed: 4,
      bath: 3,
      sqft: 2219,
      hoa: 2147,
      built: 1989,
      type: "Condo",
      mainPhoto:
        "https://photos.zillowstatic.com/fp/b3fc994bef3f60d6b4ae16b4b1d46cd9-cc_ft_1536.webp",
      nb: "Hudson Yards",
    },
    {
      address: "100 W 39th St APT 37E, New York, NY 10018",
      longitude: 40.7527756,
      latitude: -73.9860328,
      price: "880K",
      bed: 1,
      bath: 1,
      sqft: "",
      built: 2004,
      hoa: 1126,
      type: "Condo",
      mainPhoto:
        "https://photos.zillowstatic.com/fp/e50e5f1084aa8116ef2c539add9bcade-cc_ft_1536.webp",
    },
    {
      address: "252 W 30th St APT 12, New York, NY 10001",
      latitude: 40.749255,
      longitude: -73.9948853,
      price: "2.99M",
      bed: 3,
      bath: 3,
      sqft: "",
      built: 1927,
      hoa: 2785,
      type: "Condo",
      mainPhoto:
        "https://photos.zillowstatic.com/fp/bb2b9de56655cc98660c3875747ff691-cc_ft_1536.webp",
      nb: "Chelsea",
    },
    {
      address: "50 W 30th St APT 3E, New York, NY 10001",
      latitude: 40.7469949,
      longitude: -73.9893804,
      price: "2.3M",
      bed: 2,
      bath: 2,
      sqft: 1325,
      built: 2017,
      hoa: 1732,
      type: "Condo",
      mainPhoto:
        "https://photos.zillowstatic.com/fp/fd593cb023ae8eda2dbb1542a93d045a-cc_ft_1536.webp",
    },
    {
      address: "144 E 19th St, New York, NY 10003",
      latitude: 40.7363871,
      longitude: -73.9855053,
      price: "4.55M",
      bed: 4,
      bath: 6,
      built: 1910,
      type: "Townhouse",
      mainPhoto:
        "https://photos.zillowstatic.com/fp/efe036c482d853f7062699f8d68d5b4a-cc_ft_1536.webp",
    },
    {
      address: "14 W 14th St, New York, NY 10011",
      latitude: 40.736164,
      longitude: -73.9947386,
      price: "2.85M",
      bed: 2,
      bath: 2,
      built: 2008,
      hoa: 2499,
      mainPhoto:
        "https://photos.zillowstatic.com/fp/9607e6655375aa399170e158a55c321a-cc_ft_1536.webp",
    },
    {
      address: "180 Avenue C #B, New York, NY 10009",
      latitude: 40.7265281,
      longitude: -73.9766808,
      price: "5M",
      bed: 3,
      bath: 4,
      sqft: 2221,
      type: "Condo",
      built: 2014,
      hoa: 3968,
      mainPhoto:
        "https://photos.zillowstatic.com/fp/9feaebbe5b8854bcea7e54d340234cdc-cc_ft_1536.webp",
    },
    {
      address: "9 Bleecker St, New York, NY 10012",
      latitude: 40.7255585,
      longitude: -73.9928148,
      price: "6.6M",
      type: "Townhouse",
      sqft: 3357,
      built: 1884,
      mainPhoto:
        "https://photos.zillowstatic.com/fp/15f2a817b48ac97eefd9a5ce9d2d6b62-cc_ft_1536.webp",
      nb: "NoHo",
    },
    {
      address: "478 Broadway APT 4N, New York, NY 10012",
      latitude: 40.7213284,
      longitude: -73.9997604,
      price: "5.79M",
      bed: 2,
      bath: 2,
      sqft: 2200,
      built: 1900,
      hoa: 3150,
      type: "Stock Cooperative",
      mainPhoto:
        "https://photos.zillowstatic.com/fp/1453586609b7cd7f70ad046186382943-cc_ft_1536.webp",
    },
    {
      address: "62 Barrow St APT 3, New York, NY 10014",
      latitude: 40.7318697,
      longitude: -74.0054393,
      price: "1.29M",
      bed: 1,
      bath: 1,
      built: 1910,
      hoa: 1200,
      type: "Stock Cooperative",
      mainPhoto:
        "https://photos.zillowstatic.com/fp/448a1be6faff52500c63ccb4d5509cc0-cc_ft_1536.webp",
    },
  ];

  return (
    <div
      className="flex-row"
      style={{
        padding: 0,
        margin: 0,
        // height: `${"calc(100vh - 60px)"}`,
        background: "#fff",
      }}
    >
      <div className="flex-column left-side">
        <div className="flex-row topbar-box">
          <div className="hamburger-box">
            <VscMenu className="hamburger" />
          </div>
          <div
            className="flex-row input-search-box"
            onClick={() => setSearchField("123")}
          >
            {/* <div
              className="search-placeholder"
              style={{ opacity: searchField.length > 0 ? 1 : 0 }}
            >
              Search <img src={Globe} className="logo-inside-search" /> Mappfi
            </div> */}
            <div className="search-placeholder">
              <HiLocationMarker className="logo-inside-search" />
            </div>
            <div ref={geocoderContainerRef} />
            <FiSearch className="search-icon" />
          </div>
        </div>
        <div>
          <DataBar geolocation={geolocation} transition={transition} />
          <div className="toolbar-container">
            <Toolbar />
          </div>
        </div>
      </div>
      <div style={{ marginLeft: -290 }}>
        {Map?.supported ? (
          <Map
            {...viewport}
            ref={mapRef}
            maxZoom={20}
            minZoom={4}
            height="100%"
            // width={`calc(100% - ${dataBarWidth}px)`}
            width="110%"
            style={{ position: "absolute" }}
            mapboxApiAccessToken="pk.eyJ1Ijoic3BvdGJveCIsImEiOiJja21vOXRyMTcwZ2dvMnFvY29ncG13YnRrIn0.zL6jJ2Pkbm5GLS-9iLzJAA"
            onViewportChange={handleViewportChange}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            onClick={(e) => {
              // console.log(e, "<-- E");
              setShowSpot(true);
              Spot(e?.lngLat[1], e?.lngLat[0]);
            }}
          >
            <Geocoder
              mapRef={mapRef}
              containerRef={geocoderContainerRef}
              mapboxApiAccessToken="pk.eyJ1Ijoic3BvdGJveCIsImEiOiJja21vOXRyMTcwZ2dvMnFvY29ncG13YnRrIn0.zL6jJ2Pkbm5GLS-9iLzJAA"
              onViewportChange={handleGeocoderViewportChange}
              placeholder="Search MapHomes"
              countries={"us, il, ca"}
              position="top-left"
              minLength={2}
              limit={6}
              trackProximity
              marker={false}
              enableEventLogging={false}
              onInit={(e) => e._inputEl.focus()}
            />
            <GeolocateControl
              style={{ right: 30, top: 30 }}
              positionOptions={{ enableHighAccuracy: true }}
              trackUserLocation
              showAccuracyCircle={false}
              auto={false}
              showUserLocation={false}
            />
            {theSpot.latitude !== 0 && theSpot.longitude !== 0 && showSpot ? (
              <Marker
                latitude={theSpot.latitude}
                longitude={theSpot.longitude}
                draggable
                className="marker-pin"
                offsetLeft={-30}
                offsetTop={-60}
                onDragEnd={(e) => Spot(e?.lngLat[1], e?.lngLat[0])}
                position="center"
              >
                <V />
              </Marker>
            ) : (
              <>
                {!view && showSpot && (
                  <Marker
                    latitude={urlLocation.longitude}
                    longitude={urlLocation.latitude}
                    draggable
                    className="marker-pin"
                    offsetLeft={-30}
                    offsetTop={-60}
                    onDragEnd={(e) => Spot(e?.lngLat[1], e?.lngLat[0])}
                    position="center"
                  >
                    <V />
                  </Marker>
                )}
              </>
            )}
            {!theSpot?.latitude && showSpot && (
              <Marker
                className="my-pin"
                latitude={IpData.latitude}
                longitude={IpData.longitude}
                offsetLeft={-30}
                offsetTop={-60}
              >
                <V />
                {/* <O color="yellow" /> */}
              </Marker>
            )}

            {homesData?.map((e, i) => (
              <HomeButton
                latitude={e?.latitude}
                longitude={e?.longitude}
                key={i}
                address={e?.address}
                price={e?.price}
                onClick={async () => {
                  setShowSpot(false);

                  await Spot(e?.latitude, e?.longitude, true);
                  saveGlobal({
                    address: e?.address,
                    features: [],
                    number: e?.address,
                    image: e?.mainPhoto,
                    price: e?.price,
                    nb: e?.nb,
                    sqft: e?.sqft,
                    bed: e?.bed,
                    bath: e?.bath,
                    hoa: e?.hoa,
                    type: e?.type,
                    // street: "test",
                  });
                }}
              />
            ))}
          </Map>
        ) : (
          <h2 style={{ paddingTop: "240px" }}>
            Live Map is not supported on your device.
          </h2>
        )}
      </div>
    </div>
  );
};

export default MapViewURL;

{
  /* <img src="https://www.pinclipart.com/picdir/big/544-5444724_transparent-background-home-icon-png-clipart.png" /> */
}

{
  /* {geolocation.latitude && geolocation.longitude && (
            <Marker
              className="my-pin"
              latitude={geolocation.latitude}
              longitude={geolocation.longitude}
            >
              <O color="red" />
            </Marker>
          )} */
}

// https://api.mapbox.com/geocoding/v5/mapbox.places/-73.989,40.733.json?types=address&access_token=pk.eyJ1IjoianNjYXN0cm8iLCJhIjoiY2s2YzB6Z25kMDVhejNrbXNpcmtjNGtpbiJ9.28ynPf1Y5Q8EyB_moOHylw

// {showPopup && (
//   <Popup
//     latitude={parseFloat(spot.latitude)}
//     longitude={parseFloat(spot.longitude)}
//     closeOnClick={true}
//     onClose={() => togglePopup(false)}
//     tipSize={10}
//     ref={popupRef}
//     offsetTop={-20}
//     className="popup-address"
//   >
//     {console.log(spot, "THE SPOT!!!")}
//     <p>{address ? address : "Loading address..."}</p>
//   </Popup>
// )}

// const [showPopup, togglePopup] = useState(false);
// const handleAddress = (spot) => {
//   // setSpot(spot);
//   axios
//     .get(
//       `https://api.mapbox.com/geocoding/v5/mapbox.places/${spot.longitude},${spot.latitude}.json?types=address&access_token=${Token.private}`
//     )
//     .then((data) => {
//       console.log(data.data.features[0].place_name);
//       setAddress(data.data.features[0].place_name);
//     });
// };
