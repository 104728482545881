import React from "react";
import { FiShare2, FiStar, FiWifi, FiCloud, FiFlag } from "react-icons/fi";

const Toolbar = () => (
  <div className="row toolbar">
    <div className="toolbar-box">
      <FiStar className="toolbar-icon" />
    </div>
    <div className="toolbar-box">
      <FiFlag className="toolbar-icon" />
    </div>
    <div className="toolbar-box">
      <FiCloud className="toolbar-icon" />
    </div>
    <div className="toolbar-box">
      <FiWifi className="toolbar-icon" />
    </div>
    <div className="toolbar-box">
      <FiShare2 className="toolbar-icon" />
    </div>
  </div>
);

export default Toolbar;
