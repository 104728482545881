import React, { useState, useEffect, useContext } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { DatabarContext } from "../../../contexts/DatabarContext";
import ResultBox from "./ResultBox";
import API from "../../../API.json";

const Results = ({ type }) => {
  const { loading, setLoading, loadingAddress, setLoadingAddress } =
    useContext(DatabarContext);

  // useEffect(() => {
  //   console.log(
  //     "%c ⚛️ useEffect on AddressView  ",
  //     "background: blue; color: white"
  //   );
  // }, []);

  return (
    <div className="column">
      {type === "nearby" && (
        <>
          {API.nearby.map((result, i) => (
            <ResultBox
              loadingAddress={loadingAddress}
              key={i}
              result={result}
            />
          ))}
        </>
      )}
      {type === "overview" && (
        <>
          {API.overview.map((result, i) => (
            <ResultBox
              loadingAddress={loadingAddress}
              key={i}
              result={result}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default Results;
